.projects-container {
    display: flex;
    flex-direction: column;
    padding: 5% 10%;
    text-align: left;
}
  
.projects-container .projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
}
  
.projects-container .projects-grid .project {
    text-align: left;
}
  
.project h2:hover {
    text-decoration: underline;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
  
.zoom {
    border-radius: 5px;
    transition: transform .2s;
    margin: 0 auto;
    transform: scale(0.95);
}
  
.zoom:hover {
    transform: scale(1.0);
}

@media screen {
    @media (max-width: 700px) {
        .projects-container {
            display: block;
            padding-left: 5%;
            padding-right: 5%;
        }
  
        .projects-container .projects-grid {
            display: block;
        }
      
        .projects-container .projects-grid .project {
            padding-bottom: 5%;
        }
    }
}